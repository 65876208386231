/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
// require('../scss/style.scss');

// require("assets/scss/style.scss");
// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.

// var $ = require('jquery');

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
const $ = require('jquery');
global.$ = global.jQuery = $;

require('bootstrap');
require('./module/side_bar_info');
require('./module/side_bar_blog');
require('./module/user_info');
require('./module/tel_link');
require('./module/viewport');
require('./module/page_top');
require('./module/spmenu');
require('./module/regular_product.js');

$('html').mfs2019_get_user_info();
$(function(){

  $("#side-News_ArticleList").mfs2019_get_info();
  $("#side-Blog_ArticleList").mfs2019_get_blog();

  $('[data-sp-tel]').mfs2019_tel_link();
});

