$(function(){
  const toggleMenu = function() {
    $(".sw-Wrapper").toggleClass("toggled");
    $(".sw-Header_MenuToggle").toggleClass("sw-Header_MenuToggle-fix");
    $(".sw-Wrapper_Inner").toggleClass("sw-Wrapper_MenuView");
    $('body').toggleClass("sw-Wrapper_MenuOpen");
  };
  $("#menu-toggle").click(function(e) {
    e.preventDefault();
    toggleMenu();
  });
  $(document).on('click', '.toggled .sw-Header_Branding, .toggled .layout-Content', function (e) {
    e.preventDefault();
    toggleMenu();
  });
});
