const $ = require('jquery');


$.fn.mfs2019_get_user_info = function(){
  const $this = $(this);
  const $username = $('.js-user-name');

  const url = '/user_info_json.php';

  $.ajax({
    url: url,
    type: 'GET',
    async: true,
    cache: false,
    dataType: 'json',
    timeout: 10000,
    error:function() {
      $this.addClass('mfs2019-not-logged-in');
    },
    success:function(json){
      const isLoggedIn = json.is_logged_in;
      if (isLoggedIn) {
        $this.addClass('mfs2019-logged-in');
        $(function(){
          // 姓名が逆
          $('.js-customer-lastname').text(json.customer_first_name)
          $('.js-customer-firstname').text(json.customer_last_name)
        })
      } else {
        $this.addClass('mfs2019-not-logged-in');
        $(function(){
          if (json.securityToken) {
            $('.js-login-token').val(json.securityToken);
          }
        })
      }


      $(function(){
        const cart = json.cart;
        const num = cart.contents? Object.keys(cart.contents).length: 0;
        if (!num) {
          $('.js-cart-text').text('カートに商品はありません');
        } else {
          $('.js-cart-text').html('カートに' + num + '個の<br>商品があります');
        }

      })

    }
  });

  return $this;
};
