/**
 * Created by xearts on 2019/09/03.
 */
function numberFormat(num){
    return String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

function swapOptions($select, options, val) {
    $select.find('option').remove();
    $.each(options, function(index, option) {
        var $option = $('<option />').attr('value', option.id).text(option.text);
        if (val == option.id) {
            $option.attr('selected', true);
        }
        $select.append($option);
    })
}

function getTax(rate, price) {
    return Math.floor(price * rate / 100);
}

$.fn.mfs_regular_product_price = function (options, products) {

    options = $.extend({
        // products: {},
        selector_quantity: 'select[name=cart_quantity]',
        selector_product: 'select[name=products_id]',
        css_class_price: '.prod-Info_Price strong',
        css_class_tax: '.prod-Info_Tax'
    }, options);

    return $(this).each(function(){
        var $form = $(this);
        var $quantity = $form.find(options.selector_quantity);
        var $product = $form.find(options.selector_product);
        var $price = $form.find(options.css_class_price);
        var $tax = $form.find(options.css_class_tax);

        $form.find('select').change(function(){
            var quantity = 0 + $quantity.val();
            var id = $product.val();
            if (typeof products[id] === 'undefined') {
                return;
            }
            var product = products[id];
            var price = 1 * product.price * quantity;
            var tax = 1 * product.tax * quantity;
            $price.html(numberFormat(price) + '<span>円</span>');
            $tax.html('+税' + numberFormat(tax) + '円（税込 ' + numberFormat(tax + price) + '円）');
        }).change();

    });
};

$.fn.mfs_regular_product_premium_price = function (options, products) {

    options = $.extend({
        // products: {},
        premium_options: {},
        selector_quantity: 'select[name=cart_quantity]',
        selector_product: 'select[name=products_id]',
        selector_name1: 'select[name="id[1]"]',
        selector_name2: 'select[name="id[2]"]',
        selector_name3: 'select[name="id[3]"]',
        css_class_price: '.prod-Info_Price strong',
        css_class_tax: '.prod-Info_Tax'
    }, options);

    premium_options = options.premium_options;
    return $(this).each(function(){
        var $form = $(this);
        var $quantity = $form.find(options.selector_quantity);
        var $product = $form.find(options.selector_product);
        var $price = $form.find(options.css_class_price);
        var $tax = $form.find(options.css_class_tax);

        $form.find('select').change(function(){
            var quantity = 0 + $quantity.val();
            var id = $product.val();
            var $select = $(this);
            if (typeof products[id] === 'undefined') {
                return;
            }

            var $select1 = $form.find(options.selector_name1);
            var $select2 = $form.find(options.selector_name2);
            var $select3 = $form.find(options.selector_name3);

            if ($select.attr('name') === 'products_id') {
                if (typeof premium_options[id] !== 'undefined') {
                    var option = premium_options[id];
                    swapOptions($select1, option[1]);
                    swapOptions($select2, option[2]);
                    swapOptions($select3, option[3], 19);
                }
            }

            var product = products[id];
            var price = 1 * product.price;

            if (typeof premium_options[id][1][$select1.val()] !== "undefined"
                    && premium_options[id][1][$select1.val()]['price']) {
                price += premium_options[id][1][$select1.val()]['price'];
            }
            if (typeof premium_options[id][2][$select2.val()] !== "undefined"
                    && premium_options[id][2][$select2.val()]['price']) {
                price += premium_options[id][2][$select2.val()]['price'];
            }
            if (typeof premium_options[id][3][$select3.val()] !== "undefined"
                    && premium_options[id][3][$select3.val()]['price']) {
                price += premium_options[id][3][$select3.val()]['price'];
            }

            var tax = getTax(product.tax_rate, price);
            price *= quantity;
            tax *= quantity;
            $price.html(numberFormat(price) + '<span>円</span>');
            $tax.html('+税' + numberFormat(tax) + '円（税込 ' + numberFormat(tax + price) + '円）');
        }).change();

    });
};