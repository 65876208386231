const $ = require('jquery');

//------------------------------------------------------------------
//コーポレートサイトの「お知らせ」のXMLを取得して表示
//------------------------------------------------------------------
$.fn.mfs2019_get_info = function (option) {

	const $this = $(this);
	const templateFunc = option && option.templateFunc ? option.templateFunc: function($date, $title, $url) {
		return '<li class="side-News_Article">' +
					'<a href="'+$url+'">' +
						'<time class="side-News_ArticleTime">' + $date + '</time>' +
						'<h4 class="side-News_ArticleTitle">'
						+$title+'' +
						'</h4>'+
					'</a>' +
				'</li>';
	};

	$.ajax({
		url:'/news_xml.php',
		type:'GET',
		async: true,
		cache: false,
		dataType:'xml',
		timeout:10000,
		error:function() {
			var $error = '<li><p>読み込みエラー</p></li>'
			$this.append($error);
		},
		success:function(xml){
			//-------------------------------------
			//表示件数の設定
			//-------------------------------------
			var i = 0;
			var count = 10;

			//-------------------------------------
			//NEWアイコンの判定の日付を作成
			//-------------------------------------
			//判定する基準日のセット
			var $icon_flag = -7;

			//今日の日付を取得
			var now = new Date();
			$date_now=dateFormat(now);

			//判定日を計算
			$date_comparison = addDate($date_now, 'D', $icon_flag);// 日付
			//$date_comparison = addDate($date_now, 'M', $icon_flag);// 月
			//$date_comparison = addDate($date_now, 'Y', $icon_flag);// 年
			$date_comparison=dateFormat($date_comparison);

			//-------------------------------------
			//投稿のXMLデータの取得・出力処理
			//-------------------------------------
			$(xml).find("item").each(function() {
				if(i === count){
					return false;
				}
				//各項目を変数にセット
				var $date = $(this).find('date').text();
				var $title = $(this).find('title').text();
				var $url = $(this).find('url').text();
				//var $content = $(this).find('content').text();

				//出力用HTMLの生成
				var $html = templateFunc($date, $title, $url);
				$this.append($html);

				//ループのカウント
				i++;
			});

			//-------------------------------------
			// 日付の整形関数
			//-------------------------------------
			var weeks = ['日', '月', '火', '水', '木', '金', '土'];
			function dateFormat(date) {
				// 日付の変換
				var dateStr = new Date(date);
				// 年の取得
				var year = dateStr.getFullYear();
				// 月の取得
				var month = dateStr.getMonth() + 1;
				if(month < 10){
					month = '0'+month;
				}
				// 日の取得
				var day = dateStr.getDate();
				if(day < 10){
					day = '0'+day;
				}
				// 曜日の取得
				var week = dateStr.getDay();
				// 時の取得
				var hour = dateStr.getHours();
				// 分の取得
				var minute = dateStr.getMinutes();
				// 秒の取得
				var second = dateStr.getSeconds();

				//return year + '/' + month + '/' + day + '(' + weeks[week] + ') ' + hour + ':' + minute + ':' + second;
				return year + '/' + month + '/' + day;
			}

			//-------------------------------------
			//日付の加算関数
			//-------------------------------------
			function addDate(datefmt, type, n) {
				var d = new Date(datefmt);

				switch(type) {
					case 'Y':
						d.setFullYear(d.getFullYear() + Number(n));
						break;
					case 'M':
						d.setMonth(d.getMonth() + Number(n));
						break;
					case 'D':
						d.setDate(d.getDate() + Number(n));
						break;
					default:
						return null;
				}
				return [d.getFullYear(), d.getMonth() + 1, d.getDate()].join('/');
			}
		}
	});
	return $this;
} ;
