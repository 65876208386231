$(function () {
  const $pageTop = $(".sw-Footer_AnchorButton");
  const $foot = $(".sw-Footer");
  $pageTop.hide();
  $(window).on("scroll", function() {
    scrollHeight = $(document).height();
    scrollPosition = $(window).height() + $(window).scrollTop();

    if ($(this).scrollTop() > 500) {
      $pageTop.fadeIn();
    } else {
      $pageTop.fadeOut();
    }

    if ( scrollHeight - scrollPosition <= footHeight ) { //ドキュメントの高さと現在地の差がfooterの高さ以下になったら
      var footHeight = $foot.innerHeight(); //footerの高さ（＝止めたい位置）
      $pageTop.css({
        "position":"absolute", //pisitionをabsolute（親：wrapperからの絶対値）に変更
        "bottom": footHeight + 20 //下からfooterの高さ + 20px上げた位置に配置
      });
    } else {
      $pageTop.css({
        "position":"fixed",
        "bottom": "20px",
        "right": "10px"
      });
    }
  });

  $('a[href^="#"]').click(function () {
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({
      scrollTop: position
    }, 500, "swing");
    return false;
  });
});
