const $ = require('jquery');


$.fn.mfs2019_tel_link = function() {
  const mediaQueryList = window.matchMedia('screen and (max-width: 767.98px)');

  return $(this).each(function(){
    const $this = $(this);

    const tel = $this.data('sp-tel');
    if (!tel) {
      return;
    }

    const $anchor = $('<a />')
      .attr('href', 'tel:' + tel);

    if ($this.get(0).tagName == 'IMG') {
      $anchor.addClass('sw-Button-transOpacity');
    }

    $this.wrap($anchor);
    function checkBreakPoint(mql) {
      if (mql.matches) {

        $this.wrap($anchor);
      } else {
        $this.unwrap($anchor);
      }
    }

    // ブレイクポイントの瞬間に発火
    mediaQueryList.addListener(checkBreakPoint);
    // 初回チェック
    checkBreakPoint(mediaQueryList);
  });
}
