const $ = require('jquery');


// const mediaQueryList = window.matchMedia('screen and (max-width: 767.98px)');
// // const mediaQueryList = window.matchMedia('screen and (max-width: 767.98px) and (orientation: portrait)');
//
// function checkBreakPoint(mql) {
//   const $viewport = $('meta[name="viewport"]');
//   var scale = 1.0;
//   if (mql.matches) {
//     scale = "1.0";
//   } else {
//     const w = $('html').innerWidth;
//     if (w < 1000) {
//       scale = w / 1000;
//     }
//   }
//   $viewport.attr('content', 'width=device-width, initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale);
// }
//
// // ブレイクポイントの瞬間に発火
// mediaQueryList.addListener(checkBreakPoint);
// // 初回チェック
// checkBreakPoint(mediaQueryList);


function checkBreakPoint() {
  const $viewport = $('meta[name="viewport"]');
  const w = $('html').innerWidth();
  var scale = 1.0;
  if (w < 1000 && w >= 767.98) {
    scale = w / 1000;
  } else {
    scale = "1.0";
  }
  $viewport.attr('content', 'width=device-width, initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale);
}

checkBreakPoint();
